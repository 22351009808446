document.addEventListener("DOMContentLoaded", function(event)
{
 analytics();
 contact();
});

function contact()
{
 let options = {
  whatsapp: "+57 (312)4460425", // WhatsApp number
  call_to_action: "Contáctanos", // Call to action
  position: "right", // Position may be 'right' or 'left'
 };

 let proto = document.location.protocol, host = "whatshelp.io", url = proto + "//static." + host;
 let s = document.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = url + '/widget-send-button/js/init.js';
 s.onload = function () { WhWidgetSendButton.init(host, proto, options); };
 let x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x);
}

function analytics()
{
 window.dataLayer = window.dataLayer || [];

 function gtag(){
  dataLayer.push(arguments);
 }

 gtag('js', new Date());
 gtag('config', 'UA-164602512-1');
}
